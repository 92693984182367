// Component states
import states from './settings-carousel-modifier.state.js'

// Assets
import image from '@/assets/images/placeholder-image.jpg'

// Services
import { applyDrag, componentBuilder } from '@/services/utils/utils.service'
import { openEditPanel, landingPageMode } from '@/services/states/states.service'

// Components
import MdiEdit from 'vue-material-design-icons/SquareEditOutline.vue'
import MdiActionActive from 'vue-material-design-icons/Link.vue'
import MdiActionEmpty from 'vue-material-design-icons/LinkOff.vue'
import MdiDelete from 'vue-material-design-icons/Delete.vue'
import MdiCursorMove from 'vue-material-design-icons/CursorMove.vue'
import custColorPicker from '@/components/standalone/cust-color-picker/Cust-color-picker.vue'
import SliderModifier from '../slider-modifier/Slider-modifier.vue'
import { Container, Draggable } from 'vue-smooth-dnd'

/**
 * Vue declaration ------------------------------------
 */

// Name
const name = 'Settings-button-mj'

// Vue@Properties
const props = {
  conf: Object,
  mjml: Object
}
// Vue@Properties

// Vue@subComponents
const components = {
  MdiEdit,
  MdiActionActive,
  MdiActionEmpty,
  MdiDelete,
  MdiCursorMove,
  Container,
  Draggable,
  SliderModifier,
  custColorPicker
}
// Vue@subComponents

// Vue@data
const data = function () {
  return {
    states
  }
}
// Vue@data

// Methods
const methods = {

  /**
   * Open Modal
   * @param  {Integer} index (Panel index)
   */
  openModal (index) {
    openEditPanel({
      parent: { mjml: this.mjml },
      contents: this.mjml.children,
      isModal: true,
      index
    })
  },

  /**
   * Get image URL when this one isn't on error
   * @param  {Object} panel (Panel)
   *
   * @return {String}       (src || placeholder)
   */
  imageUrl (panel, index) {
    const id = panel.id
    const src = panel.attributes.src
    const hasFallbackSrc = !!this.mjml.attributes.src
    const hasFallBackActive = !this.mjml.attributes['css-class'].includes('fallback-desktop-false')
    const isFirstPanel = index === 0

    this._checkImageSource(id, src)
    if (hasFallBackActive && !hasFallbackSrc && isFirstPanel) { 
      this.mjml.attributes.src = src
    }
    return this.states.imagesOnError[id] || !src ? image : src
  },

  /**
   * Generate image with correct size
   * @param {String} src (url)
   */
  _checkImageSource (id, src) {
    const img = new Image()
    img.onload = () => {
      this.$set(this.states.imagesOnError, id, false)
    }
    img.onerror = () => {
      this.$set(this.states.imagesOnError, id, true)
    }
    img.src = src
  },

  // Func@addPan
  /**
   * Add new panel
   */
  async addPanel () {
    const newPanel = await componentBuilder({
      componentId: 'mj-carousel-image-v2',
      withId: true
    })
    this.mjml.children.push(newPanel)
  },
  // Func@addPan

  // Func@deletePanel
  /**
   * Delete existing panel
   * @param  {Integer} index (panel position)
   */
  deletePanel (index) {
    if (this.mjml.children.length > 1) this.mjml.children.splice(index, 1)
  },
  // Func@deletePanel

  // Func@onDrop
  /**
   * To get the droped list and replace the current
   * @param  {Object} dropResult (D&D params)
   */
  onDrop (dropResult) {
    const newArray = applyDrag(this.mjml.children, dropResult)
    this.mjml.children.splice(0, this.mjml.children.length, ...newArray)
  },
  // Func@onDrop

  // Func@getPayload
  /**
   * Get DND payload
   * @param  {Number} index (position)
   *
   * @return {Object}       (payload)
   */
  getPayload (index) {
    return this.mjml.children[index]
  }
  // Func@getPayload
}

// Computed Methods
const computed = {

  /**
   * Check DND mode
   */
  isLandingMode () {
    return landingPageMode().get()
  }
}

// Vue component syntax
export default {
  name,
  data,
  props,
  methods,
  computed,
  components
}
